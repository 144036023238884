<template>
    <div>
        <iframe src="https://arenacx-sirius.youcanbook.me?VMTG=acx-sirius-np0ydv" id="iframearenacx" class="iFrame" frameborder="0" allowtransparency="true" 
            style="height: 550px; width: 100%;"
            ></iframe>
    </div>
</template>

<script>
export default {
    created() {
        
    },

    mounted() {

    }
};
</script>

<style scoped>

</style>